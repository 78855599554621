/** @format */

import {
  Box,
  Divider,
  Grid,
  Typography,
  TextField,
  Button,
  Paper,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Dialog,
  DialogContentText,
  Container,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import animationData from "../../../../src/assets/Lottie/FormSubmission.json";
import Lottie from "react-lottie";
import { PRIVATE_URLS } from "../../../services/urlConstants";
import { get, post } from "../../../services/apiMethods";
import CountDownTimer from "../../../components/ExamConduct/CountDownTimer";
import { LoadingButton } from "@mui/lab";
import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs";

const OnlineExamDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const { examDetails } = location.state || {};
  const [data, setData] = useState([]);
  const [modifiedList, setModifiedList] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [submitExam, setSubmitExam] = useState(false);
  const [loading, setLoading] = useState(false);
  const [instructionViewed, setInstructionViewed] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [codeOfConduct, setCodeOfConductData] = useState([]);
  const handleCheckboxChange = (event) => {
    setCheckboxChecked(event.target.checked);
  };
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const isLastQuestion = currentQuestion === modifiedList.length - 1;
  const allAnswered = modifiedList.every((question) => question.providedAnswer);
  useEffect(() => {
    if (data?.questionSet) {
      setModifiedList(data?.questionSet?.map((q) => ({ ...q })));
    }
  }, [data]);

  useEffect(() => {
    getOnlineExamDetails();
    getCodeOfConduct();
  }, []);

  const getCodeOfConduct = async () => {
    try {
      const { data } = await get(PRIVATE_URLS.codeOfConduct.list);

      setCodeOfConductData(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const getOnlineExamDetails = async () => {
    try {
      const { data } = await get(PRIVATE_URLS.student.getOnlineExamDetails, {
        params: {
          examScheduleId: id,
        },
      });
      setData(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleNext = () => {
    if (currentQuestion < modifiedList.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handleExam = () => {
    setInstructionViewed(true);
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };
  const disableSubmission = () => setData(null);

  const handleOptionChange = (e) => {
    const optionId = e.target.value;
    const newList = modifiedList.map((q, i) =>
      i === currentQuestion ? { ...q, providedAnswer: optionId } : q
    );
    setModifiedList(newList);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedAnswers = modifiedList.map((q) => q?.providedAnswer);
    const payload = {
      answers: selectedAnswers,
      examScheduleId: id,
    };
    try {
      setLoading(true);
      await post(PRIVATE_URLS.student.submitExam, payload);

      setOpenDialog(false);
      setLoading(false);
      setSubmitExam(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const startDate = new Date(examDetails?.date);
  const startTimeParts = examDetails?.slot?.startTime?.split(":");
  if (startTimeParts && startTimeParts.length === 2) {
    const [startHour, startMinute] = startTimeParts.map(Number);
    startDate.setHours(startHour, startMinute, 0, 0);
  }
  const endDate = new Date(examDetails?.date);
  const endTimeParts = examDetails?.slot?.endTime?.split(":");
  if (endTimeParts && endTimeParts.length === 2) {
    const [endHour, endMinute] = endTimeParts.map(Number);
    endDate.setHours(endHour, endMinute, 0, 0);
  }
  const durationInMinutes = (endDate - startDate) / (1000 * 60);
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;

  return (
    <>
      {!submitExam ? (
        <>
          {instructionViewed ? (
            <>
              {" "}
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "90vh",
                  padding: "10px",
                }}>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}>
                  <Paper
                    elevation={1}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "20px",
                      height: "100%",
                      minHeight: "480px",
                    }}>
                    {" "}
                    <CountDownTimer
                      disableSubmission={disableSubmission}
                      endTime={examDetails.slot.endTime}
                    />
                    <Typography
                      fontWeight="bold"
                      mb={2}
                      mt={2}
                      sx={{ fontSize: "18px" }}
                      variant="h6">
                      Questions:
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        maxHeight: "100%",
                        overflowY: "auto",
                      }}>
                      {modifiedList.map((question, index) => (
                        <Button
                          key={index}
                          variant="outlined"
                          size="medium"
                          onClick={() => setCurrentQuestion(index)}
                          sx={{
                            minWidth: "50px",
                            padding: "10px 20px",
                            backgroundColor: modifiedList[index].providedAnswer
                              ? "green"
                              : "default",
                            "&:hover": {
                              backgroundColor: modifiedList[index]
                                .providedAnswer
                                ? "green"
                                : "default",
                              color: modifiedList[index].providedAnswer
                                ? "white"
                                : "default",
                            },
                            color: modifiedList[index].providedAnswer
                              ? "white"
                              : "default",
                            borderRadius: "8px",
                            boxShadow: 1,
                            fontSize: "16px",
                          }}>
                          {index + 1}
                        </Button>
                      ))}
                    </Box>
                  </Paper>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}>
                  <Paper
                    elevation={1}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "20px",
                      height: "100%",
                      minHeight: "480px",
                    }}>
                    <Typography
                      sx={{
                        fontWeight: (theme) =>
                          theme.typography.fontWeightMedium,
                        fontSize: "18px",
                        marginBottom: "20px",
                        color: "#1f1d1d",
                      }}>
                      <span style={{ fontWeight: "bold" }}>
                        {currentQuestion + 1}
                      </span>
                      . {modifiedList[currentQuestion]?.question}{" "}
                      <span>
                        (Marks:{modifiedList[currentQuestion]?.maximumMarks}){" "}
                      </span>
                      .{" "}
                    </Typography>
                    <Grid container marginTop={0}>
                      {modifiedList[currentQuestion]?.isMcq ? (
                        modifiedList[currentQuestion].options?.map(
                          (option, k) => (
                            <Grid item xs={6} sm={6} md={6} lg={6} key={k}>
                              <FormControl component="fieldset" mb={1}>
                                <RadioGroup
                                  value={
                                    modifiedList[currentQuestion].providedAnswer
                                  }
                                  onChange={(e) => handleOptionChange(e)}>
                                  <FormControlLabel
                                    value={option}
                                    control={<Radio size="small" />}
                                    label={option}
                                    style={{ paddingBottom: "5px" }}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          )
                        )
                      ) : (
                        <Grid item xs={12}>
                          <TextField
                            multiline
                            rows={30}
                            fullWidth
                            variant="outlined"
                            placeholder="Type your answer here"
                            value={
                              modifiedList[currentQuestion]?.providedAnswer ||
                              ""
                            }
                            onChange={(e) => handleOptionChange(e)}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  alignItems: "center",
                  marginBottom: "10px",
                }}>
                {modifiedList.length > 1 &&
                  modifiedList.indexOf(modifiedList[currentQuestion]) > 0 && (
                    <Button
                      onClick={handlePrevious}
                      size="small"
                      variant="contained"
                      color="info">
                      Previous
                    </Button>
                  )}
                {modifiedList.length > 1 &&
                  modifiedList.indexOf(modifiedList[currentQuestion]) <
                    modifiedList.length - 1 && (
                    <Button
                      onClick={handleNext}
                      size="small"
                      variant="outlined"
                      color="info">
                      Next
                    </Button>
                  )}
                {isLastQuestion && (
                  <Button
                    variant="contained"
                    size="small"
                    color="success"
                    disabled={!allAnswered}
                    onClick={handleOpenDialog}>
                    Submit
                  </Button>
                )}
              </Box>
              <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                sx={{
                  borderRadius: "12px",
                }}>
                <Box
                  sx={{
                    width: "400px",
                    maxWidth: "100%",
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "#333",
                    }}>
                    Confirm Submission
                  </Typography>
                  <DialogContentText
                    sx={{
                      textAlign: "center",
                      color: "#666",
                      marginBottom: "20px",
                    }}>
                    Are you sure you want to submit this exam? This action
                    cannot be undone.
                  </DialogContentText>
                  <Divider sx={{ width: "100%", marginBottom: "20px" }} />

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}>
                    <Button
                      onClick={handleCloseDialog}
                      variant="outlined"
                      color="error"
                      sx={{
                        width: "45%",
                        fontWeight: "bold",
                        borderRadius: "5px",
                      }}>
                      Cancel
                    </Button>
                    <LoadingButton
                      onClick={handleSubmit}
                      variant="contained"
                      loading={loading}
                      color="primary"
                      sx={{
                        width: "45%",
                        fontWeight: "bold",
                        borderRadius: "5px",
                      }}>
                      Yes, Submit
                    </LoadingButton>
                  </Box>
                </Box>
              </Dialog>
            </>
          ) : (
            <Container maxWidth="xl">
              <Box
                sx={{
                  backgroundColor: "#42a5f5",
                  padding: "16px",
                  borderRadius: "8px 8px 0 0",
                  textAlign: "center",

                  borderBottom: "10px solid #0d47a1",
                }}>
                <Typography
                  variant="h5"
                  sx={{
                    color: "white",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}>
                  Instructions
                </Typography>
              </Box>

              <Container maxWidth="md" sx={{ marginY: 4 }}>
                <Typography
                  color="error"
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginTop: 2,
                  }}>
                  Please read the instructions before starting the exam.
                </Typography>
                <Box
                  sx={{
                    padding: 3,
                    backgroundColor: "#f1f1f1",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  }}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: 2,
                    }}>
                    {[
                      {
                        label: "Exam Name",
                        value: examDetails?.examTitle?.name,
                      },
                      {
                        label: "Subject",
                        value: `${examDetails?.subject?.name}-${examDetails?.subject?.subjectCode}`,
                      },

                      {
                        label: "Date",
                        value: dayjs(examDetails?.date).format("DD-MM-YYYY"),
                      },
                      {
                        label: "Time",
                        value: `${examDetails?.slot?.startTime} - ${examDetails?.slot?.endTime}`,
                      },
                      {
                        label: "Duration",
                        value: `${hours}h : ${minutes}min`,
                      },
                    ].map((detail, index) => (
                      <Typography key={index} sx={{ fontWeight: 600 }}>
                        {detail.label}:{" "}
                        <span style={{ color: "#42a5f5", fontSize: "16px" }}>
                          {detail.value}
                        </span>
                      </Typography>
                    ))}
                  </Box>
                </Box>
              </Container>

              <Container maxWidth="md">
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    General Instructions
                  </Typography>
                  <List>
                    {codeOfConduct.map((rule, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          backgroundColor: "#f9f9f9",
                          borderRadius: "8px",
                          marginY: 1,
                          padding: 2,
                        }}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: "bold" }}>
                              {index + 1}. {rule.title}
                            </Typography>
                          }
                          secondary={rule.description}
                        />
                      </ListItem>
                    ))}
                  </List>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkboxChecked}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={
                      <Typography color={"error"}>
                        I have read and understand the instructions for this
                        exam.
                        <Typography
                          component={"span"}
                          color={"error"}
                          sx={{ marginLeft: "4px" }}>
                          *
                        </Typography>
                      </Typography>
                    }
                    sx={{ marginY: 2 }}
                  />
                </Box>
              </Container>

              <Divider sx={{ marginY: 4 }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}>
                <Button
                  disabled={!checkboxChecked}
                  color="success"
                  size="medium"
                  onClick={handleExam}
                  variant="contained">
                  Start Exam
                </Button>
              </Box>
            </Container>
          )}
        </>
      ) : (
        <>
          {" "}
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 3,
              }}>
              <Box
                sx={{
                  width: "300px",
                  height: "300px",
                  backgroundColor: "#fff",
                }}>
                <Lottie options={defaultOptions} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ textAlign: "center" }}>
              <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
                Thank You!
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{ textAlign: "center", mt: 2 }}>
              Your exam has been submitted successfully.
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default OnlineExamDetails;
