/** @format */

import { Box, Typography, styled } from "@mui/material";
import React from "react";
import themeData from "../../../data/themeData";
import { useLocation } from "react-router-dom";

const MuiBox = styled(Box)(({ theme, bgcolor }) => ({
  overflow: "hidden",
  background: bgcolor,
  flexShrink: 0,
  width: "100%",
  boxSizing: "border-box",
  padding: "10px",
  textAlign: "center",
}));

const SplashNewsHorizontal = ({ horizontalData = [], selectedTheme }) => {
  let text = horizontalData.map((d) => `${d.text}`);
  const location = useLocation();

  if (
    location.pathname.startsWith("/sch") ||
    location.pathname.startsWith("/login") ||
    location.pathname.startsWith("/log") ||
    location.pathname.startsWith("/current-stats-table") ||
    location.pathname.startsWith("/forgot-password") ||
    location.pathname.startsWith("/online_exam_details")
  ) {
    return null;
  }

  const backgroundColor =
    selectedTheme === 3 ? "#8cc152" : themeData.darkPalette.primary.main;

  return (
    <MuiBox bgcolor={backgroundColor}>
      <marquee direction="left">
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "20px",
            color: "#fff",
          }}>
          {text.join(" || ")}
        </Typography>
      </marquee>
    </MuiBox>
  );
};

export default SplashNewsHorizontal;
